export function getSeason() {
    const currentMonth = new Date().getMonth() + 1;

    var season = "";
    
    switch (currentMonth) {
      // 春
      case 3:
      case 4:
        season = "spring";
        break;
      // 夏
      case 5:
      case 6:
      case 7:
      case 8:
        season = "summer";
        break;
      // 秋
      case 9:
      case 10:
        season = "autumn";
        break;
      // 冬
      case 11:
      case 12:
      case 1:
      case 2:
        season = "winter";
        break;
      default:
        season = "error";
        break;
    }

    return season;
}