<template>
  <div class="cuisine">
    <div class="wrap">
      <UiDish title="お料理" />
    </div>
    <UiSliderBox
      :images="images"
      :swiperOption="swiperOption"
      :height="100"
      :width="100"
      :button="false"
      class="menu-slider"
    />

    <div class="wrap">
      <div class="cover">
        <UiPrice
          :type="0"
          description="季節にあわせた鍋料理(ふぐ、スッポン、鴨、<br class=sp-only>あんこう等)もご用意できます。"
        />
        <p class="note">
          お飲み物は、こだわりの銘酒や焼酎、ワイン<br class="sp-only">など各種ご用意しております。
        </p>
        <p class="note space">
          個室をご利用の方は、1名様につき880円を<br class="sp-only">頂戴しております。
        </p><p class="note">サービス料はいただいておりません。</p>
        <p class="note">価格は全て税込みです。</p>

        <p class="note space">※ご予約のキャンセルについて</p>
        <p class="note">
          当日または前日キャンセルされる場合は、<br class="sp-only">下記料金を頂戴いたします。
        </p>
        <p class="note pc-only">何卒、お客様のご理解とご協力を賜りますようお願い申し上げます。</p>
        <p class="note space">当日…100％&emsp;前日…50％</p>
      </div>
    </div>
  </div>
</template>

<script>
import UiDish from "@/components/Ui/Dish.vue";
import UiPrice from "@/components/Ui/Price.vue";
import UiSliderBox from "@/components/Ui/SliderBox.vue";
export default {
  name: "cuisine",
  components: {
    UiSliderBox,
    UiDish,
    UiPrice,
  },
  data() {
    return {
      images: [
        { id: 1, imgpath: "hirano_oshinagaki-2021aki004.jpg", name: "日本酒" },
        { id: 2, imgpath: "hirano_oshinagaki-2021aki002.jpg", name: "鮎" },
        { id: 3, imgpath: "hirano_oshinagaki-top2022haru005.jpg", name: "トマト" },
        { id: 4, imgpath: "hirano_oshinagaki-2021aki006.jpg", name: "傘" },
        { id: 5, imgpath: "hirano_oshinagaki-2021aki003.jpg", name: "椀物" },
        { id: 6, imgpath: "hirano_oshinagaki-top2022haru004.jpg", name: "ちらし寿司" },
        { id: 7, imgpath: "hirano_oshinagaki-top2022haru003.jpg", name: "トマト" },
        { id: 8, imgpath: "hirano_oshinagaki-2021aki008.jpg", name: "蕎麦" },
        { id: 9, imgpath: "hirano_oshinagaki-2021aki005.jpg", name: "八寸" },
        { id: 10, imgpath: "hirano_oshinagaki-top2021fuyu001.jpg",name: "お玉"},
        { id: 11, imgpath: "hirano_oshinagaki-top2022haru001.jpg", name: "トマト" },
        { id: 12, imgpath: "hirano_oshinagaki-top2022haru002.jpg", name: "筍" },
        { id: 13, imgpath: "hirano_oshinagaki-top2021fuyu002.jpg",name: "牡蠣"},
      ],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        speed: 1000,
        effect: "coverflow",
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        //ナビゲーション設定
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1200: {
          slidesPerView: 2,
        },
        600: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  created() {
    if (window.matchMedia("(min-width:768px)").matches) {
      //no action.
    } else if (window.matchMedia("(max-width: 767px)").matches) {
      this.swiperOption.direction = "horizontal";
      this.swiperOption.slidesPerView = "1.2";
      this.swiperOption.speed = 800;
      this.swiperOption.spaceBetween = 0;
      // this.swiperOption.autoplay.delay = "400";
    } else {
      //no action.
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.dish {
  .top {
    text-align: center;
  }
}

.note {
  font-size: 14px;
  line-height: 20px;;
  text-align: center;
  color: white;
  margin-bottom: 7px;
}
.note.space {
  margin-top: 30px;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .cover {
    background-color: rgba($color: black, $alpha: 0.9) !important;
    margin-top: 100px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    padding-top: 50px;

    @media screen and (max-width: 767px) {
      width: 100%;
      // background-color: transparent;
    }
  }
}
//スライダー

.xslider_wrap {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.xslider1,
.xslider2 {
  display: inline-flex;
  flex: none;
  animation: xslider 120s linear infinite;
  @media screen and (max-width: 767px) {
    animation: xslider 30s linear infinite;
  }

  div {
    flex: none;
    padding: 10px;

    img {
      @media screen and (max-width: 767px) {
        width: 300px;
      }
    }
  }
}

@keyframes xslider {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

::v-deep.swiper-button-prev {
  color: white !important;
}
::v-deep.swiper-button-next {
  color: white !important;
}
.menu-slider {
  @media screen and (max-width: 767px) {
    margin-left: 25px;
  }
}
</style>
