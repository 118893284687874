<template>
  <div class="gallery">
    <div class="cover">
      <div class="garally-top" :class="{ 'over-baseline': scrollY > 500 }">
        <div class="text">
          <span>一期一会の<br />&nbsp;&nbsp;&nbsp;&nbsp;おもてなし</span>
        </div>
        <img
          class="maru"
          :src="`${rootUrl}/img/takumi-hirano_pa001.png`"
          alt="月１"
        />
        <div class="dish">
          <div class="line">
            <div class="line2">
              <div class="lineinappear">
                <img
                  :src="`${rootUrl}` + dish1"
                  alt="料理１"
                  class="dish-1-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="garally-middle" :class="{ 'over-baseline': scrollY > 600 }">
        <div class="text-2">
          <span>旬な食材を<br />&nbsp;&nbsp;&nbsp;&nbsp;旬な時期に楽しむ</span>
        </div>
        <img
          class="maru-2"
          :src="`${rootUrl}/img/takumi-hirano_pa003.png`"
          alt="月2"
        />
        <div class="dish-2">
          <div class="line3">
            <div class="line4">
              <div class="lineinappear">
                <img
                  :src="`${rootUrl}` + dish2"
                  alt="料理2"
                  class="dish-2-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="garally-bottom" :class="{ 'over-baseline': scrollY > 700 }">
        <div class="text-3">
          <span
            >匠の技で織りなす<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;多彩な味わい</span
          >
        </div>
        <img
          class="maru-3"
          :src="`${rootUrl}/img/takumi-hirano_pa002.png`"
          alt="月3"
        />
        <div class="dish-3">
          <div class="line5">
            <div class="line6">
              <div class="lineinappear">
                <img
                  :src="`${rootUrl}` + dish3"
                  alt="料理3"
                  class="dish-3-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { getSeason } from '../../store/season';
export default {
  name: "gallery",
  components: {},
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
      dish1: "/img/hirano-top-2022haru002.jpg",
      dish2: "/img/hirano-top-2022haru001.jpg",
      dish3: "/img/hirano-top-2022haru003.jpg"
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  mounted() {
    this.setImage();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer === null) {
        this.scrollTimer = setTimeout(
          function () {
            this.scrollY = window.scrollY;
            clearTimeout(this.scrollTimer);
            this.scrollTimer = null;
            // console.log(this.scrollY);
          }.bind(this),
          200
        );
      }
    },
    setImage() {
      const season = getSeason();

      if (season === "spring") {
        this.dish1 = "/img/hirano-top-2022haru002.jpg";
        this.dish2 = "/img/hirano-top-2022haru001.jpg";
        this.dish3 = "/img/hirano-top-2022haru003.jpg";
      } else if (season === "summer") {
        this.dish1 = "/img/takumi-hirano_023.jpg";
        this.dish2 = "/img/takumi-hirano_027.jpg";
        this.dish3 = "/img/takumi-hirano_026.jpg";
      } else if (season === "fall") {
        this.dish1 = "/img/hirano-top-2021aki001.jpg";
        this.dish2 = "/img/hirano-top-2021aki002.jpg";
        this.dish3 = "/img/hirano-top-2021aki003.jpg";
      } else if (season === "winter") {
        this.dish1 = "/img/hirano-top-2021fuyu001.jpg";
        this.dish2 = "/img/hirano-top-2021fuyu002.jpg";
        this.dish3 = "/img/hirano-top-2021fuyu003.jpg";
      } else {
        alert("情報取得に失敗しました。");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  .cover {
    position: relative;

    @media screen and (max-width: 767px) {
    }
    .garally-top,
    .garally-bottom {
      display: flex;
      padding: 150px 0;
    }
    .text,
    .text-2,
    .text-3 {
      writing-mode: vertical-rl;
      letter-spacing: 0.5em;
      font-size: 28px;
      line-height: 1.8em;
      z-index: 1;
      white-space: nowrap;

      @media screen and (max-width: 767px) {
        font-size: 22px;
      }
    }
    .dish-1-img,.dish-2-img,.dish-3-img{
      display: none;
    }
    .garally-top {
      justify-content: center;
      .dish {
        width: 8%;
        margin-bottom: 5%;
        overflow: visible;
        @media screen and (min-width:767px) and ( max-width:621px){
          width: 32%;
        }
         @media screen and (max-width: 622px) {
           width: 38%;
           min-width:250px;

         }
        img {
          vertical-align: top;
          width: 100%;
        }
      }
      .text {
        margin-right: 5%;
        // @media screen and (max-width: 767px) {
        //   left: 0;
        //   z-index: 10;
        // }
      }
    }
    .garally-bottom {
      justify-content: center;
      @media screen and (max-width: 767px) {
        justify-content: 	normal;
         }
      .dish-3 {
        width: 35%;
        display: flex;
        justify-content: flex-end;
        @media screen and (max-width: 767px) {
         justify-content: flex-start;
         margin-top: 70px;
         }
        img {
          vertical-align: top;
          // object-fit: cover;
        }
        .dish-3-img {
          width: 330px;
          @media screen and (min-width:768px) and ( max-width:970px){
width: 280px;
          }
          @media screen and (max-width: 767px) {
         width: 250px;
         
         }
        }
      }
      .text-3 {
        width: 65%;
        height: auto;
        span{
           white-space: nowrap;
        }
        @media screen and (max-width: 767px) {
          width: 25%;
          // left: 0;
          // top: 65%;
          // z-index: 10;
        }
      }
    }
    .garally-middle {
      // position: absolute;
      width: 100%;

      .dish-2 {
        position: absolute;
        top: 45%;
        left: 2%;
        height: auto;
        width:100%;

        @media screen and (max-width: 767px) {
          // position: static;
          width: 75%;
          top: 37%;
          z-index: 1;
        }
        .dish-2-img {
          width: 450px;
          height: auto;
          vertical-align: top;
          @media screen and (min-width:768px) and ( max-width:970px){
width: 370px;
          }
          @media screen and (max-width: 767px) {
            width: 350px;
          }
        }
      }
      .text-2 {
        position: absolute;
        bottom: -11%;
        left: 27%;

        @media screen and (max-width: 767px) {
          left: 75%;
          bottom: 35%;
          z-index: 10;
        }
      }
    }

    .over-baseline {
      .dish-1-img,.dish-2-img,.dish-3-img{
      display: initial;
    }
      .line,.line3,.line5 {
        position: absolute; /* 枠線が書かれる基点*/
        // width: 100%;
      }

      /*上下線*/
      .line::before,
      .line::after {
        position: absolute;
        content: "";
        width: 0;
        height: 1px;
        background: white; /* 枠線の色*/
      }

      /*左右線*/
      .line2::before,
      .line2::after {
        position: absolute;
        content: "";
        width: 1px;
        height: 0;
        background: white; /* 枠線の色*/
      }

      /*上線*/
      .line::before {
        top: 1;
        left: 0;
        animation: lineAnime 2s linear 0s backwards; /*表示されて0秒後に上線が0.5秒かけて表示*/
      }

      /*右線*/
      .line2::before {
        top: 0;
        right: 0;
        animation: lineAnime2 2s linear 0.5s backwards; /*表示されて0.5秒後に右線が0.5秒かけて表示*/
      }

      /*下線*/
      // .line::after {
      //   bottom: 0;
      //   right: 0;
      //   animation: lineAnime 2s linear 0.5s backwards; /*表示されて1秒後に下線が0.5秒かけて表示*/
      // }

      /*左線*/
      // .line2::after {
      //   bottom: 0;
      //   left: 0;
      //   animation: lineAnime2 2s linear 1.5s backwards; /*表示されて1.5秒後に左線が0.5秒かけて表示*/
      // }
            /*上下線*/
      .line3::before,
      .line3::after {
        position: absolute;
        content: "";
        width: 0;
        height: 1px;
        background: white; /* 枠線の色*/
      }

      /*左右線*/
      .line4::before,
      .line4::after {
        position: absolute;
        content: "";
        width: 1px;
        height: 0;
        background: white; /* 枠線の色*/
      }

      /*上線*/
      .line3::before {
        top: 1;
        left: 0;
        animation: lineAnime 2s linear 1s backwards; /*表示されて0秒後に上線が0.5秒かけて表示*/
      }

      /*右線*/
      .line4::before {
        top: 0;
        right: 0;
        animation: lineAnime2 2s linear 1.5s backwards; /*表示されて0.5秒後に右線が0.5秒かけて表示*/
      }

      /*下線*/
      // .line3::after {
      //   bottom: 0;
      //   right: 0;
      //   animation: lineAnime 2s linear 1.5s backwards; /*表示されて1秒後に下線が0.5秒かけて表示*/
      // }

      /*左線*/
      // .line4::after {
      //   bottom: 0;
      //   left: 0;
      //   animation: lineAnime2 2s linear 2s backwards; /*表示されて1.5秒後に左線が0.5秒かけて表示*/
      // }
            /*上下線*/
      .line5::before,
      .line5::after {
        position: absolute;
        content: "";
        width: 0;
        height: 1px;
        background: white; /* 枠線の色*/
      }

      /*左右線*/
      .line6::before,
      .line6::after {
        position: absolute;
        content: "";
        width: 1px;
        height: 0;
        background: white; /* 枠線の色*/
      }

      /*上線*/
      .line5::before {
        top: 1;
        left: 0;
        animation: lineAnime 2s linear 1.5s backwards; /*表示されて0秒後に上線が0.5秒かけて表示*/
      }

      /*右線*/
      .line6::before {
        top: 0;
        right: 0;
        animation: lineAnime2 2s linear 2s backwards; /*表示されて0.5秒後に右線が0.5秒かけて表示*/
      }

      /*下線*/
      // .line5::after {
      //   bottom: 0;
      //   right: 0;
      //   animation: lineAnime 2s linear 2s backwards; /*表示されて1秒後に下線が0.5秒かけて表示*/
      // }

      /*左線*/
      // .line6::after {
      //   bottom: 0;
      //   left: 0;
      //   animation: lineAnime2 2s linear 2.5s backwards; /*表示されて1.5秒後に左線が0.5秒かけて表示*/
      // }
      @keyframes lineAnime {
        0% {
          width: 0%;
        }
        100% {
          width: 100%;
        }
      }

      @keyframes lineAnime2 {
        0% {
          height: 0%;
        }
        100% {
          height: 100%;
        }
      }


      /*枠線内側の要素*/
      .line .lineinappear {
        opacity: 0; /*初期値を透過0にする*/
        animation: lineInnerAnime 2s linear 0.8s forwards; /*1.5秒後に中央のエリアが0.5秒かけて表示*/
      }
      .line3 .lineinappear {
        opacity: 0; /*初期値を透過0にする*/
        animation: lineInnerAnime 2s linear 1.2s forwards; /*1.5秒後に中央のエリアが0.5秒かけて表示*/
      }
      .line5 .lineinappear {
        opacity: 0; /*初期値を透過0にする*/
        animation: lineInnerAnime 2s linear 1.7s forwards; /*1.5秒後に中央のエリアが0.5秒かけて表示*/
      }

      @keyframes lineInnerAnime {
        0% {
          opacity: 0;

        }
        100% {
          opacity: 1;
        }
      }
    }

    .maru,
    .maru-2,
    .maru-3 {
      width: 17%;
      position: absolute;
      z-index: 0;
    }
    .maru {
      right: 42%;
      top: 3%;

      @media screen and (max-width: 767px) {
        left: 20%;
        top: 10%;
      }
    }
    .maru-2 {
      left: 16%;
      bottom: 19%;
       @media screen and (min-width:768px) and ( max-width:970px){
         bottom: 28%;
       }

      @media screen and (max-width: 767px) {
        left: 20%;
        bottom: 14%;
      }
    }
    .maru-3 {
      right: 16%;
      bottom: 38%;

      @media screen and (max-width: 767px) {
        right: 13%;
        bottom:45%;
      }
    }
  }
}
</style>
