<template>
  <div class="home-end">
    <div class="box">
      <div
        class="parallax_content1 pc-only"
        :style="`background-image: url(${rootUrl}` + parallaxImage + ';'"
        v-prlx.background="{
          direction: 'y',
          fromBottom: true,
          speed: 0.15,
        }"
      >
      </div>
      
      <div
        class="parallax_content1 sp-only"
        :style="`background-image: url(${rootUrl}` + parallaxImage + ';'"
        v-prlx.mobile.background="{direction: 'x' ,fromBottom:false, speed: 0.1}" 
      >
      </div>
    </div>
  </div>
</template>

<script>
import { getSeason } from '../../store/season';

export default {
  name: "home-end",
  components: {},
  data() {
    return {
      parallaxImage: "/img/parallax/end-spring.jpg",
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  mounted() {
    this.setImage();
  },
  //   created() {
  //   if (window.matchMedia("(min-width:768px)").matches) {
  //     //no action.
  //   } else if (window.matchMedia("(max-width: 767px)").matches) {
  //     this.direction = "x";
  //     this.speed = 0.5;
  //   } else {
  //     //no action.
  //   }
  // },
  methods: {
    setImage() {
      const season = getSeason();

      if (season === "spring") {
        this.parallaxImage = "/img/parallax/end-spring.jpg";
      } else if (season === "summer") {
        this.parallaxImage = "/img/parallax/end-summer.jpg";
      } else if (season === "autumn") {
        this.parallaxImage = "/img/parallax/end-autumn.jpg";
      } else if (season === "winter") {
        this.parallaxImage = "/img/parallax/end-winter.jpg";
      } else {
        alert("情報の取得に失敗しました。");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
//元の画像
.parallax_content1 {
  width: 100%;
  height: 400px;
  background-size: cover;
  // background-size: 150%;
  // background-size: auto 100vh;
  // background-position: center;
  // background-attachment: fixed;
  // position: -webkit-sticky;
  // position: sticky;
  // // top: 0;

  @media screen and (max-width: 767px) {
    // background-size: 50%;
    // background-attachment: scroll;
    // background-position: bottom;
    background-size: 200% auto;
    background-repeat: no-repeat;
    background-position: center;
    height: 420px;
  }
}

//新作
// .box{
//   width: 100%;
//   .parallax_content1{
//   width: 100%;
//   height: 400px;
//       position: -webkit-sticky;
//   position: sticky;
//   top: 0;
//   }
// }
</style>
